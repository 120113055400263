@import 'sass';

.Book {
	position: relative;
	height: 100%;
	width: 100%;
	border-radius: 10px;
	overflow: visible;
	background: rgba($color: $color-light-two, $alpha: 1);
	cursor: pointer;

	transition: all 1s cubic-bezier(0.39, 0.575, 0.565, 1);

	@include flex-center;
	gap: 1rem;
	h4 {
		font-family: 'Marcellus', serif;
		font-size: 22px;
		letter-spacing: 0.5pt;
		@media screen and (max-width: $breakpoint-small) {
			font-size: 20px;
		}
		@media screen and (max-height: $breakpoint-mobile) {
			font-size: 18px;
		}
		@media screen and (max-width: $breakpoint-mobile) {
			font-size: 16px;
		}
		text-transform: uppercase;
	}

	.SelectDates {
		@include flex-center;
		gap: 1rem;
		width: 100%;
		height: 100%;
	}

	.Modal {
		@include modal;
		@include bookingModal;
		.Back {
			z-index: 2;
			position: absolute;
			top: 2vh;
			right: 2vh;
			color: $color-three;
			font-size: 30px;
			cursor: pointer;
		}

		.IframeContainer {
			overflow: auto;
			width: 100%;
			height: 100%;
			background-color: $color-light;
			display: flex;
			justify-content: center;
			align-items: flex-end;
		}
	}
}
