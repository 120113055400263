@import 'sass';

.AmenitiesMobile {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	.Header {
		width: 80%;
		display: flex;
		justify-content: space-between;
		margin-bottom: 10px;
		h2 {
			align-self: flex-start;
			// padding-left: 10%;
			font-size: 20px;
			display: block;
			font-family: 'Marcellus', serif;
			letter-spacing: 1pt;
		}
		h4 {
			padding-top: 3px;
			font-family: 'Roboto', serif;
			font-weight: 100;
			font-size: 15px;
			border-bottom: 0.5px solid;
		}
	}
	.MobileMatrix {
		height: 100%;
		width: 100%;
		padding: 3% 10%;
		display: grid;

		grid-template-columns: repeat(4, 1fr);
		grid-auto-rows: 75px;
		@media screen and (max-width: $breakpoint-mobile) {
			grid-template-columns: repeat(3, 1fr);
		}

		.Amenity {
			position: relative;
			@include flex-center;
			flex-direction: column;
			text-align: center;
			.Icon {
				position: relative;
				@include flex-center;

				font-size: 30px;
				transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
			}
			.Description {
				margin-top: 10px;
				position: relative;
				font-size: 10px;
				font-family: 'Roboto', serif;
				font-weight: 100;
				text-align: center;
				max-width: 100%;
				transition: all 0.3s ease-in;
			}

			&All0 {
				@extend .Amenity;
				display: none;
			}
			&All1 {
				@extend .Amenity;
				display: none;
			}
		}
		.Amen {
			opacity: 0;
			&Show {
				@extend .Amen;
				animation: fadeInLift 1s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards;
			}
		}
	}

	&0 {
		@extend .AmenitiesMobile;
		color: black;
	}
	&1 {
		@extend .AmenitiesMobile;
		color: white;
	}
	&2 {
		@extend .AmenitiesMobile;
		color: black;
	}
}
