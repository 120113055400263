// COLORS ---->
$color-dark: #52758a;
// $color-dark: #366481;
// $color-dark: #59829c;
$color-light: #f0efeb;
$color-light-two: #dcd8cd;
$color-three: #dab3ae;
$color-grey: #545454;
$color-red: #a22929;
$color-green: #29a239;
// $color-three: #f5cac3;

$color-aqua: #90d2d2;
$color-yellow: #dfd294;
// $color-aqua: #a8f0f0;

// BREAKPOINTS ---->
$breakpoint-mobile: 500px;
$breakpoint-small: 750px;
$breakpoint-tablet: 1000px; // at this point make sure to change to mobile booking component

$breakpoint-large: 1600px;
$breakpoint-desktop: 2100px;
