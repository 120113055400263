@import 'sass';

.FullScreenMobile {
	z-index: 10;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: black;
	@include flex-center;

	.Back {
		z-index: 2;
		position: absolute;
		top: 20px;
		left: 15px;
		color: white;
		font-size: 30px;
		cursor: pointer;
	}
	.Tracker {
		z-index: 1;
		position: absolute;
		top: 20px;
		left: 50%;
		transform: translateX(-50%);
		color: white;
		font-size: 30px;
		font-weight: 100;
	}

	.CarouselFrameOuter {
		z-index: 0;
		position: relative;
		width: 100%;
		height: 100%;
		overflow-y: visible;
		overflow-x: scroll;
		scroll-snap-type: x mandatory;
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		&::-webkit-scrollbar {
			display: none;
		}
		.CarouselFrameInner {
			height: 100%;
			display: flex;
			flex-direction: row;
			overflow: visible;
			transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
			.Current {
				height: 100%;
				@include flex-center;

				.Photo {
					flex-shrink: 0;
					scroll-snap-align: center;
					@media screen and (orientation: portrait) {
						height: auto;
						width: 100vw;
					}
					@media screen and (orientation: landscape) {
						height: 100%;
						width: auto;
						margin: 0 10px;
					}
				}
				.Description {
					z-index: 100;
					position: absolute;
					bottom: 50px;
					padding: 0 5%;
					width: 100vw;
					font-size: 18;
					font-weight: 300;
					color: white;
					text-align: center;
					@media screen and (orientation: landscape) {
						display: none;
					}
				}
			}
		}
	}
}
