@import 'sass';

.container {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

.loading {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-size: 16px;
	font-weight: 300;
	text-transform: uppercase;
}

.skChase {
	width: 100px;
	height: 100px;
	position: relative;
	animation: sk-chase 2.5s infinite linear both;
}

.skChaseDot {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	animation: sk-chase-dot 2s infinite ease-in-out both;
}

.skChaseDot:before {
	content: '';
	display: block;
	width: 15%;
	height: 15%;
	background-color: $color-dark;
	border-radius: 100%;
	animation: sk-chase-dot-before 2s infinite ease-in-out both;
}

.skChaseDot:nth-child(3n)::before {
	background-color: $color-dark;
}
.skChaseDot:nth-child(3n-1)::before {
	background-color: $color-aqua;
}
.skChaseDot:nth-child(3n-2)::before {
	background-color: $color-three;
}

.skChaseDot:nth-child(1) {
	animation-delay: -1.1s;
}
.skChaseDot:nth-child(2) {
	animation-delay: -1s;
}
.skChaseDot:nth-child(3) {
	animation-delay: -0.9s;
}
.skChaseDot:nth-child(4) {
	animation-delay: -0.8s;
}
.skChaseDot:nth-child(5) {
	animation-delay: -0.7s;
}
.skChaseDot:nth-child(6) {
	animation-delay: -0.6s;
}
.skChaseDot:nth-child(1):before {
	animation-delay: -1.1s;
}
.skChaseDot:nth-child(2):before {
	animation-delay: -1s;
}
.skChaseDot:nth-child(3):before {
	animation-delay: -0.9s;
}
.skChaseDot:nth-child(4):before {
	animation-delay: -0.8s;
}
.skChaseDot:nth-child(5):before {
	animation-delay: -0.7s;
}
.skChaseDot:nth-child(6):before {
	animation-delay: -0.6s;
}

@keyframes sk-chase {
	100% {
		transform: rotate(360deg);
	}
}

@keyframes sk-chase-dot {
	80% {
		transform: rotate(360deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes sk-chase-dot-before {
	0% {
		transform: scale(1);
	}
	50% {
		transform: scale(0.4);
	}
	100% {
		transform: scale(1);
	}
}
