@keyframes bounce {
	from {
		transform: translateY(0);
	}
	60% {
		transform: translateY(10px);
	}
	to {
		transform: translateY(0);
	}
}
@keyframes sideBounce {
	from {
		transform: translateX(0);
	}
	60% {
		transform: translateX(15px);
	}
	to {
		transform: translateX(0);
	}
}

@keyframes fadeInLift {
	from {
		opacity: 0;
		transform: translateY(50%);
	}
	to {
		opacity: 1;
		transform: translateY(0%);
	}
}

@keyframes bounce {
	from {
		transform: translateY(0);
	}
	60% {
		transform: translateY(15px);
	}
	to {
		transform: translateY(0);
	}
}
