@import 'sass';

.Gallery {
	height: 100%;
	width: 100%;
	@include flex-center;
	flex-direction: column;

	nav {
		height: 10%;
		width: 100%;
		ul {
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: space-evenly;
			align-items: center;

			.Li {
				list-style: none;
				font-family: 'Marcellus', serif;
				transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
				color: gray;
				cursor: pointer;
				border-bottom: 1px solid rgba($color: $color-aqua, $alpha: 0);

				&Current {
					@extend .Li;
					color: black;
					border-bottom: 1px solid rgba($color: $color-aqua, $alpha: 1);
				}
			}
		}
	}

	.Arrow {
		position: absolute;
		height: fit-content;
		width: 5%;
		cursor: pointer;
		@include flex-center;

		font-size: 25px;
		color: $color-dark;

		transition: opacity 0.1s linear;
		&:active {
			opacity: 0.7;
		}

		&0 {
			@extend .Arrow;
			color: $color-dark;
		}
		&1 {
			@extend .Arrow;
			color: $color-light;
		}
		&2 {
			@extend .Arrow;
			color: $color-dark;
		}
	}

	.Disable {
		opacity: 0.7;
		cursor: default;
		&:active {
			opacity: 0.7;
		}
	}

	.CarouselFrameOuter {
		position: relative;
		height: 90%;
		width: 80%;
		overflow: hidden;
		// overflow-x: scroll;
		.CarouselFrameInner {
			height: 100%;
			display: flex;
			flex-direction: row;
			overflow: visible;
			transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

			.Photos {
				flex-shrink: 0;
				height: 100%;
				display: flex;
				justify-content: space-around;
				align-items: center;

				.Photo {
					position: relative;
					height: 90%;
					width: 35vw;
					max-width: 60vh;
					border-radius: 10px;
					background-size: cover;
					background-repeat: no-repeat;
					background-position: center;
					cursor: pointer;
					.Expand {
						color: white;
						position: absolute;
						top: 18px;
						right: 18px;
						font-size: 22px;
						opacity: 0;
						transition: all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
					}
					&:hover {
						.Expand {
							opacity: 1;
						}
					}
					&::after {
						content: '';
						height: 100%;
						width: 100%;
						position: absolute;
						top: 0;
						left: 0;
						border-radius: 10px;
						background-color: black;
						opacity: 0;
						transition: all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
					}
					&:hover::after {
						opacity: 0.1;
					}
				}
			}
		}
	}
	.ShowFull {
		position: absolute;
		top: 100%;
		right: 10%;
		font-size: 15px;
		border: 1px solid;
		border-radius: 10px;
		padding: 5px 10px;
		@media screen and (max-width: $breakpoint-tablet) {
			font-size: 12px;
			bottom: -25px;
			left: -15px;
		}
		font-weight: 100;
		cursor: pointer;
		span {
			margin-left: 5px;
		}

		transition: all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);

		&:hover {
			background-color: rgba($color: $color-light-two, $alpha: 0.3);
		}

		&0,
		&2 {
			@extend .ShowFull;
			border-color: $color-dark;
			color: black;
		}

		&1 {
			@extend .ShowFull;
			border-color: $color-light;
			color: $color-light;
		}
	}
}
