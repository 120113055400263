@mixin flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

@mixin inner {
	height: 100%;
	width: 100%;
	max-width: 2500px;
}

@mixin sectionSize {
	height: 80vh;
	width: 100%;
}

@mixin button {
	cursor: pointer;
	@include flex-center();
	text-align: center;
	background-color: $color-light-two;
	border: 1px solid black;
	border-radius: 10px;
	font-weight: 100;
	letter-spacing: 1pt;
	font-size: 18px;
	transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);

	&:hover {
		background-color: $color-light;
	}
}

@mixin modal {
	position: fixed;
	z-index: 10;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	@include flex-center();

	.ModalBackground {
		z-index: -10;
		position: absolute;
		height: 100%;
		width: 100%;
		background: rgba($color: black, $alpha: 0.5);
	}

	.Inner {
		position: relative;
		height: 70%;
		width: 60%;
		max-width: 1100px;
		@media screen and (max-width: $breakpoint-tablet) {
			width: 80%;
		}
		@media screen and (max-width: $breakpoint-mobile), (max-height: $breakpoint-small) {
			width: 100%;
			height: 100%;
		}
		overflow: hidden;
		background-color: $color-light;
		border: 2px solid $color-dark;
		border-radius: 10px;
		@media screen and (max-width: $breakpoint-mobile), (max-height: $breakpoint-small) {
			border: none;
			border-radius: 0;
		}
		animation: fadeInLift 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);

		.Close {
			position: absolute;
			top: 15px;
			right: 15px;
			@media screen and (max-width: $breakpoint-small) {
				top: 10px;
				right: 10px;
			}
			font-size: 28px;
			color: $color-dark;
			cursor: pointer;
		}

		.Content {
			height: 100%;
			width: 100%;
			overflow-y: auto;
			padding: 50px 10%;
			@media screen and (max-width: $breakpoint-small) {
				padding: 50px 7%;
			}

			h1 {
				font-size: 32px;
				@media screen and (max-width: $breakpoint-small), (max-height: $breakpoint-small) {
					font-size: 24px;
				}
				margin-bottom: 25px;
				font-family: 'Marcellus', serif;
			}
			h4 {
				font-size: 22px;
				@media screen and (max-width: $breakpoint-small), (max-height: $breakpoint-small) {
					font-size: 18px;
				}
				font-family: 'Marcellus', serif;
				margin-top: 25px;
				margin-bottom: 15px;
			}
			p {
				font-size: 18px;
				@media screen and (max-width: $breakpoint-small), (max-height: $breakpoint-small) {
					font-size: 16px;
				}
				font-weight: 300;
				letter-spacing: 0.5pt;
				line-height: 1.3;
			}
			li {
				height: fit-content;
				width: 100%;
				list-style: none;
				font-weight: 300;
				letter-spacing: 0.5pt;
				display: flex;
				align-items: center;
				justify-content: flex-start;
				margin: 10px 0;
				font-size: 18px;
				@media screen and (max-width: $breakpoint-small), (max-height: $breakpoint-small) {
					font-size: 16px;
				}

				.Icon {
					color: $color-dark;
					text-align: left;
					margin-right: 15px;
					font-family: 'Roboto', serif;
					font-weight: 100;
					font-size: 20px;
				}
			}
		}
	}
}

@mixin bookingModal {
	.Inner {
		width: 875px;
		max-width: 95%;
		height: fit-content;
		@media screen and (max-width: $breakpoint-small) {
			width: 500px;
		}
		@media screen and (max-width: $breakpoint-mobile), (max-height: $breakpoint-small) {
			width: 100%;
			max-width: none;
			height: 100%;
		}
		background-color: white;
		.Content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			@media screen and (max-width: $breakpoint-mobile), (max-height: $breakpoint-small) {
				display: grid;
				grid-template-rows: auto 100px;
				justify-content: center;
			}
			.SelectDates {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				h1 {
					align-self: flex-start;
				}
				.DateRange {
					border: 1px solid $color-dark;
					border-radius: 0 0 10px 10px;
				}
			}
			.Save {
				height: fit-content;
				@include flex-center();
				flex-direction: column;
				margin-top: 25px;
				@media screen and (max-width: $breakpoint-mobile), (max-height: $breakpoint-small) {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 100px;
					border-top: 2px groove;
					background-color: white;
					margin-top: none;
				}
				.MinStay {
					width: 100%;
					height: 25px;
					.MinStayNotMet {
						width: fit-content;
						margin: 10px auto 0;
						@media screen and (max-width: $breakpoint-mobile), (max-height: $breakpoint-small) {
							margin: 10px auto 25px;
						}
						color: $color-red;
						font-style: italic;
						animation: fadeInLift 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
					}
				}
			}
		}
	}
}
@mixin guestsModal {
	.Inner {
		width: 350px;
		max-width: 95%;
		height: fit-content;

		@media screen and (max-width: $breakpoint-mobile), (max-height: $breakpoint-small) {
			width: 100%;
			max-width: none;
			height: 300px;
			border-radius: 10px 10px 0 0;
			position: absolute;
			bottom: 0;
		}
		background-color: white;
		.Content {
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: center;
			height: fit-content;
			@media screen and (max-width: $breakpoint-mobile), (max-height: $breakpoint-small) {
				display: grid;
				grid-template-rows: auto 100px;
				justify-content: center;
			}
			.SelectGuests {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: flex-start;
				h1 {
					align-self: flex-start;
				}
				.Guests {
					@include flex-center();
					.Icon {
						font-size: 25px;
						color: grey;
						@include flex-center();
						margin-right: 10px;
					}
					.Select {
						position: relative;
						height: 52px;
						width: 130px;
						@include flex-center();
						font-size: 18px;
						font-weight: 300;

						.Count {
							position: absolute;
							width: 100px;
							height: 100%;
							left: 0;
							top: 0;
							@include flex-center();
							cursor: default;
							h4 {
								margin-top: 0;
								margin-bottom: 0;
							}
						}

						.Arrows {
							padding: 2%;
							position: absolute;
							height: 100%;
							width: 30px;
							top: 0;
							right: 0;
							display: flex;
							flex-direction: column;
							justify-content: space-between;
							align-items: center;
							transition: all 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);

							.Arrow {
								height: 22px;
								width: 22px;
								@include flex-center();
								cursor: pointer;
								font-size: 14px;

								&:active {
									background-color: rgba($color: gray, $alpha: 0.1);
								}
							}

							.Disable {
								color: rgba($color: black, $alpha: 0.5);
								cursor: default;
							}
						}
					}
				}
			}
			.Save {
				height: fit-content;
				@include flex-center();
				flex-direction: column;
				margin-top: 25px;
				@media screen and (max-width: $breakpoint-mobile), (max-height: $breakpoint-small) {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 100px;
					border-top: 2px groove;
					background-color: white;
					margin-top: none;
				}
			}
		}
	}
}

@mixin errorModal {
	.Inner {
		max-width: 95%;
		height: fit-content;

		.Button {
			margin-top: 10px;
		}
	}
}
