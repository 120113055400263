@import 'sass';

.FullScreenWeb {
	z-index: 10;
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background-color: $color-light;
	@include flex-center;

	.Back {
		z-index: 2;
		position: absolute;
		top: 4vh;
		left: 4vh;
		color: $color-three;
		font-size: 40px;
		cursor: pointer;
	}

	nav {
		position: absolute;
		top: 20%;
		left: 0;
		width: 20%;
		height: 40%;
		@include flex-center;
		ul {
			height: 100%;
			width: 100%;
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			flex-direction: column;

			.Li {
				list-style: none;
				font-family: 'Marcellus', serif;
				color: gray;
				cursor: pointer;
				font-size: 20px;
				transition: all 0.5s cubic-bezier(0.39, 0.575, 0.565, 1);
				border-bottom: 1px solid rgba($color: $color-aqua, $alpha: 0);

				&Current {
					@extend .Li;
					color: black;
					border-bottom: 1px solid $color-three;
				}
			}
		}
	}

	.Description {
		position: absolute;
		width: 20%;
		height: 30%;
		left: 0;
		bottom: 0;
		padding: 2%;
		font-weight: 300;
		background-color: $color-light-two;
		// border-radius: 50%;
		@include flex-center;
		p {
			font-size: 22px;
			color: $color-dark;
			color: $color-light;
			font-weight: 100;
			color: black;
			text-align: center;
		}

		.Tracker {
			z-index: 1;
			position: absolute;
			top: 10px;
			right: 10px;
			color: white;
			font-size: 24px;
			font-weight: 100;
		}
	}

	.Scroll {
		position: absolute;
		right: 82%;
		bottom: 5%;
		color: $color-dark;
		width: 92px;
		// height: 50px;
		// border: 1px solid black;
		h4 {
			position: absolute;
			left: 0;
			top: 5px;
			font-size: 18px;
			font-family: 'Marcellus', serif;
			letter-spacing: 1pt;
			margin-right: 10px;
		}
		.Dash {
			color: $color-dark;
			position: absolute;
			right: 0;
			top: 0;
			font-size: 35px;
			animation: sideBounce 1.5s infinite;
		}
	}

	.CarouselFrameOuter {
		z-index: 0;
		position: absolute;
		top: 0;
		right: 0;
		width: 80%;
		height: 100%;
		overflow-y: visible;
		overflow-x: scroll;
		// scroll-snap-type: x mandatory;
		scrollbar-width: none; /* Firefox */
		-ms-overflow-style: none; /* Internet Explorer 10+ */
		&::-webkit-scrollbar {
			display: none;
		}
		.CarouselFrameInner {
			height: 100%;
			display: flex;
			flex-direction: row;
			overflow: visible;
			transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);

			.Photo {
				flex-shrink: 0;
				height: 100%;
				width: auto;
				margin: 0 10px;

				&:first-of-type {
					margin-left: 0;
				}
			}
		}
	}
}
